import React from "react";

const Stars = ({ color }) => {
  return (
    <svg viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M5.60033 10.6167L4.21491 7.56875L1.16699 6.18333L4.21491 4.79792L5.60033 1.75L6.98574 4.79792L10.0337 6.18333L6.98574 7.56875L5.60033 10.6167ZM10.617 12.25L9.93158 10.7188L8.40033 10.0333L9.93158 9.33333L10.617 7.81667L11.317 9.33333L12.8337 10.0333L11.317 10.7188L10.617 12.25Z" fill="#333333"/>
    </svg>
  );
};
export default Stars;
